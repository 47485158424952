import React, { useState } from 'react'
import { Loading, TableGroup } from '@therms/atalaya'
import { useUrlState } from '@src/hooks/useUrlState'
import { GetEscOccurencesByTimeRange } from '@src/services/api-client'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import { useSetState } from 'ahooks'
import dayjs from 'dayjs'
import { EscalationOccurrencesTableControls } from '@src/modules/Escalations/router/screens/Index/components/EscalationOccurrences/EscalationOccurrencesTableControls'
import { EscalationOccurrencesTableHeadRow } from '@src/modules/Escalations/router/screens/Index/components/EscalationOccurrences/EscalationOccurrencesTableHeadRow'
import { EscalationOccurrencesTableRow } from '@src/modules/Escalations/router/screens/Index/components/EscalationOccurrences/EscalationOccurrencesTableRow'
import { EscalationOccurrenceModal } from '@src/modules/Escalations/router/screens/Index/components/EscalationOccurrences/EscalationOccurrenceModal'
import { EscOccurrenceList } from '@src/generatedClient/generated/taskreportsApi'

export type EscOccurrencesSearchFilters = {
  startDate: string
  endDate: string
  isResolved: boolean
}

function EscalationOccurrencesTable() {
  const [showEscOccurrenceModal, setShowEscOccurrenceModal] = useState<
    EscOccurrenceList['escOccurrences'][number] | undefined
  >()
  const [searchTextValue, setSearchText] = useUrlState('escoccsrc')

  const [searchFilters, setSearchFilters] = useSetState<
    Partial<EscOccurrencesSearchFilters>
  >({
    startDate: dayjs().subtract(30, 'days').toISOString(),
    endDate: dayjs().toISOString(),
    isResolved: false,
  })

  const [limit, setLimit] = useUrlState('escocctlimit', 25)
  const [skip, setSkip] = useUrlState('escocctskip', 0)

  const { call, data, isCalling } = useApiWithSiteId(
    GetEscOccurencesByTimeRange,
    {
      ...searchFilters,
      addVerificationFileUrls: true,
      offset: skip,
      limit,
    },
  )

  return (
    <div className="relative">
      <Loading overlay visible={isCalling && !data?.escOccurrences?.length} />

      <TableGroup
        data={data?.escOccurrences || []}
        keyProp="id"
        Controls={
          <EscalationOccurrencesTableControls
            refreshTableData={call}
            setSearchFilters={setSearchFilters}
            searchFilters={searchFilters}
            // setSearchText={setSearchText}
            // searchTextValue={searchTextValue}
          />
        }
        HeadRow={EscalationOccurrencesTableHeadRow}
        Row={EscalationOccurrencesTableRow}
        onClickRow={({ rowData }) => setShowEscOccurrenceModal(rowData as any)}
        onSetRecordsPerPage={setLimit}
        onSetSkippedRecords={setSkip}
        recordsPerPage={limit}
        skippedRecords={skip}
        totalRecords={data?.total}
      />

      {showEscOccurrenceModal && (
        <EscalationOccurrenceModal
          closeHandler={() => {
            setShowEscOccurrenceModal(null)

            call()
          }}
          escOccurrence={showEscOccurrenceModal}
        />
      )}
    </div>
  )
}

EscalationOccurrencesTable.propTypes = {}

export { EscalationOccurrencesTable }
