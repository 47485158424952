import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Button, Loading } from '@therms/atalaya'
import { useUrlState } from '@src/hooks/useUrlState'
import { useParams } from 'react-router-dom'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { format } from 'date-fns'
import {
  getTemplateReportDailyData,
  TemplateReportDailyData,
} from '@src/generatedClient/generated/taskreportsApi'
import { ActivityList } from './components/ActivityList'
import { EscalationList } from './components/EscalationList'

interface ViewInteractiveReportProps {
  siteId: string
}

function ViewInteractiveReport({ siteId }: ViewInteractiveReportProps) {
  const [loading, setLoading] = useState(false)

  const { dateRange } = useParams<{
    dateRange?: string
  }>()

  const [templateReportId] = useUrlState('trId', '')

  const [endDate] = useUrlState('endDate', new Date().toISOString())
  const [startDate] = useUrlState(
    'startDate',
    dateRange
      ? dayjs().subtract(30, 'day').toISOString()
      : dayjs().subtract(1, 'day').toISOString(),
  )

  const getLocalizedDate = (d: string | Date) => {
    if (typeof d !== 'string') return new Date(d)
    const parts = d.split('-')
    return new Date(
      parseInt(parts[0], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[2], 10),
    )
  }

  const [currentDate, setCurrentDate] = useState<Date>(
    getLocalizedDate(startDate),
  )
  const [currentReport, setCurrentReport] = useState<TemplateReportDailyData>()

  // Generate array of ISO timestamps for the current day

  useEffect(() => {
    setCurrentDate(getLocalizedDate(startDate))
  }, [startDate])

  const handlePreviousDay = () => {
    if (currentDate === getLocalizedDate(startDate)) return
    const d = getLocalizedDate(currentDate)
    d.setDate(d.getDate() - 1)
    setCurrentDate(d)
  }

  const handleNextDay = () => {
    if (currentDate === getLocalizedDate(endDate)) return
    const d = getLocalizedDate(currentDate)
    d.setDate(d.getDate() + 1)
    setCurrentDate(d)
  }

  const fetchFunction = async (d: string) => {
    const data = await getTemplateReportDailyData({
      siteId,
      startDate: d,
      templateReportId,
    })
    return { date: d, data }
  }

  useEffect(() => {
    const run = async () => {
      try {
        setLoading(true)
        const data = await fetchFunction(currentDate.toISOString())
        setCurrentReport(data.data.data)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    run()
  }, [currentDate])

  return (
    <div>
      <div className="text-lg">Activity Report</div>

      <div className="flex items-center mt-4 justify-between">
        <Button
          onClick={handlePreviousDay}
          outline
          variant="neutral"
          className="!px-4 !py-1"
          disabled={currentDate <= getLocalizedDate(startDate)}
        >
          <ChevronLeftIcon className="h-5 w-5" />
          Previous Day
        </Button>

        <p className="font-medium">
          {format(getLocalizedDate(currentDate), 'MMM d, yyyy')}
        </p>

        <Button
          onClick={handleNextDay}
          outline
          variant="neutral"
          className="!px-4 !py-1"
          disabled={currentDate >= getLocalizedDate(endDate)}
        >
          Next Day
          <ChevronRightIcon className="h-5 w-5" />
        </Button>
      </div>

      {loading && <Loading overlay />}

      <div>
        <div>
          <ActivityList
            siteId={siteId}
            activities={currentReport?.completions || []}
            comments={currentReport?.comments || []}
          />
        </div>

        <div>
          <EscalationList
            siteId={siteId}
            escOccurrences={currentReport?.escOccurrences || []}
          />
        </div>
      </div>
    </div>
  )
}

ViewInteractiveReport.defaultProps = {}

export { ViewInteractiveReport }
