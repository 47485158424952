import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { UserGetUser } from '@src/services/api-client'
import { useAuth } from '@src/modules/Auth/shared/state'

function UserName({ id, className }) {
  const [data, setData] = useState(undefined)
  const { currentSite } = useAuth()
  const siteId = currentSite.id
  useEffect(() => {
    if (!id) {
      setData(undefined)
      return
    }

    UserGetUser(
      { userId: id, siteId },
      {
        cache: {
          ttl: 60000 * 10,
        },
      },
    ).then((res) => {
      setData(res.data)
    })
  }, [id])

  return <span className={className}>{data?.name}</span>
}

UserName.propTypes = {
  id: PropTypes.string.isRequired,
  className:
    PropTypes.string || PropTypes.arrayOf(PropTypes.string) || PropTypes.object,
}

export { UserName }
