import React, { useState } from 'react'

import { EscOccurrence } from '@src/generatedClient/generated/taskreportsApi'
import { Card, DateTime, Inline, Modal, Stack } from '@therms/atalaya'
import { EscalationName } from '@src/modules/Escalations/shared/EscalationName'
import { TaskName } from '@src/modules/Tasks/shared/TaskName'
import { FiCheckCircle } from 'react-icons/fi'
import { UserName } from '@src/modules/Users/shared/UserName'
import { format } from 'date-fns'
import { EscalationOccurrenceDetail } from './EscalationOccurrenceDetail'

interface EscalationListProps {
  siteId: string
  escOccurrences: EscOccurrence[]
}

function EscalationList({ siteId, escOccurrences }: EscalationListProps) {
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)
  const [selectedEscOccurrence, setSelectedEscOccurrence] =
    useState<EscOccurrence | null>(null)
  return (
    <div className="mt-5">
      <h2 className="text-lg mb-4">Escalations</h2>

      <div
        className="bg-white rounded-lg shadow"
        style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
      >
        {escOccurrences?.length === 0 ? (
          <p className="p-4 py-10 text-center text-gray-500">
            No activities for this day.
          </p>
        ) : (
          <Stack divideY="default">
            {escOccurrences?.map((escOccurrence) => (
              <Card
                key={escOccurrence.id}
                background="background"
                padding="base"
                role="button"
                tabIndex={0}
                onClick={() => {
                  setSelectedEscOccurrence(escOccurrence)
                  setShowDetailModal(true)
                }}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <EscalationName id={escOccurrence.escalationId} />

                    <div className="mt-2 text-sm">
                      <span
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          escOccurrence.resolvedDate
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        }`}
                      >
                        {escOccurrence.resolvedDate ? 'Resolved' : 'Unresolved'}
                      </span>{' '}
                      | {format(new Date(escOccurrence.timestamp), 'hh:mm a')}
                    </div>
                  </div>
                  <div>
                    {escOccurrence.resolvedDate && (
                      <div className="text-sm font-light">
                        <span className="inline-block">
                          <FiCheckCircle color="green" size={14} />
                        </span>
                        Resolved by{' '}
                        <UserName
                          className="italic"
                          id={escOccurrence.resolvingUserId}
                        />
                        (<DateTime timestamp={escOccurrence.resolvedDate} />)
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            ))}
          </Stack>
        )}

        {showDetailModal && (
          <Modal
            closeHandler={() => {
              setShowDetailModal(false)
              setSelectedEscOccurrence(null)
            }}
            escPressClose
            header="Escalation Issue"
          >
            <EscalationOccurrenceDetail escOccurrence={selectedEscOccurrence} />
          </Modal>
        )}
      </div>
    </div>
  )
}

export { EscalationList }
