import { useState } from 'react'

export function useRemoteStorage() {
  const [isUploading, setIsUploading] = useState(false)

  const storageUploader = async (
    remoteTargetUri: string,
    file: File,
    successCb: () => void,
    errorCb: () => void,
  ) => {
    setIsUploading(true)

    try {
      // Create FormData for file upload
      const formData = new FormData()
      formData.append('file', file)

      const response = await fetch(remoteTargetUri, {
        method: 'PUT', // or POST based on your server's requirement
        body: file, // Sending the file directly for binary data upload
        headers: {
          'Content-Type': file.type, // Set file MIME type dynamically
        },
      })

      if (response.ok) {
        successCb()
      } else {
        throw new Error(`HTTP Error: ${response.status}`)
      }
    } catch (err) {
      console.error('Upload Error:', err)

      // Show an alert and allow retry
      if (window.confirm('Upload failed. Retry?')) {
        storageUploader(remoteTargetUri, file, successCb, errorCb)
      } else {
        errorCb()
      }
    } finally {
      setIsUploading(false)
    }
  }

  return {
    storageUploader,
    isUploading,
  }
}
