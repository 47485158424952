import React, { useState } from 'react'
import {
  Comment,
  Completion,
} from '@src/generatedClient/generated/taskreportsApi'
import { Card, Modal, Stack } from '@therms/atalaya'
import { format } from 'date-fns'
import { findComments } from '@src/modules/Sites/router/screens/ViewSite/components/SinglePagePdf'
import { ActivityDetail } from './ActivityDetail'

interface ActivityListProps {
  activities: Completion[]
  comments: Comment[]
  siteId: string
}

function ActivityList({ activities, comments, siteId }: ActivityListProps) {
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)
  const [selectedActivity, setSelectedActivity] = useState<Completion | null>(
    null,
  )

  const handleActivitySelection = (activity: Completion) => {
    setSelectedActivity(activity)
    setShowDetailModal(true)
  }

  const computeNumberOfImages = (activity: Completion): number =>
    findComments(activity.commentIds, comments)
      .filter((comment) =>
        comment.fileUrls?.some((url) => url.toLowerCase().endsWith('.jpg')),
      )
      .map(
        (comment) =>
          comment.fileUrls.filter((url) =>
            url.toLowerCase().endsWith('.jpg'),
          ) as string[],
      )
      .flat().length

  return (
    <div className="mt-5">
      <h2 className="text-lg mb-4">Tasks</h2>

      <div
        className="bg-white rounded-lg"
        style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
      >
        {activities.length === 0 ? (
          <p className="p-4 py-10 text-center text-gray-500">
            No activities for this day.
          </p>
        ) : (
          <Stack divideY="default">
            {activities.map((activity) => (
              <Card
                key={activity.id}
                background="background"
                padding="base"
                role="button"
                onClick={() => handleActivitySelection(activity)}
                tabIndex={0}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">
                      {activity.title}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {activity.description}
                    </p>
                  </div>
                  {activity.completedAtTime ? (
                    <div className="text-sm text-gray-500">
                      Completion Date:{' '}
                      {format(activity.completedAtTime, 'hh:mm a')}
                    </div>
                  ) : (
                    activity.dueDate && (
                      <div className="text-sm text-gray-500">
                        Due Date:{' '}
                        {format(activity.dueDate, 'MMM dd, yyyy hh:mm a')}
                      </div>
                    )
                  )}
                </div>
                <div className="mt-2 text-sm">
                  <span
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      activity.isComplete && 'bg-green-100 text-green-800'
                    }
                    ${
                      !activity.isSkipped &&
                      !activity.isComplete &&
                      'bg-red-100 text-red-800'
                    } ${activity.isSkipped && 'bg-yellow-100 text-yellow-800'}`}
                  >
                    {activity.status}
                  </span>
                  {computeNumberOfImages(activity) > 0 && (
                    <span className="ml-2 text-gray-500">
                      {computeNumberOfImages(activity)} image
                      {computeNumberOfImages(activity) > 1 ? 's' : ''}
                    </span>
                  )}
                </div>
              </Card>
            ))}
          </Stack>
        )}
      </div>

      {showDetailModal && (
        <Modal
          closeHandler={() => setShowDetailModal(false)}
          escPressClose
          header={selectedActivity?.title || 'Activity Details'}
        >
          <ActivityDetail
            siteId={siteId}
            activity={selectedActivity}
            comments={comments}
          />
        </Modal>
      )}
    </div>
  )
}

ActivityList.defaultProps = {}

export { ActivityList }
