import { Alerts } from '@src/components/Alerts'
import { deleteTemplateReport } from '@src/generatedClient/generated/taskreportsApi'
import { delErrorCheck } from '@src/services/api-resp-checks'
import { Button, ButtonWithActionModal, Inline } from '@therms/atalaya'
import React from 'react'

type Props = {
  onClickNewTemplateReport: () => void
  selectedRowsKeys?: string[]
  clearSelectedRows?: () => void
  refreshTableData: () => void
}

function TemplateReportTableControls({
  onClickNewTemplateReport,
  selectedRowsKeys,
  clearSelectedRows,
  refreshTableData,
}: Props) {
  return (
    <Inline alignX="between" alignY="baseline" className="pb-3" width="full">
      <Inline alignY="center" space="xs">
        {
          selectedRowsKeys?.length > 0 ? (
            <>
              <div className="text-color-subtle text-sm">
                {selectedRowsKeys?.length} Selected
              </div>

              <ButtonWithActionModal
                actionText="Delete"
                body="Are you sure you want to delete these Reports?"
                onAction={async () => {
                  try {
                    await Promise.all(
                      selectedRowsKeys.map((id) =>
                        deleteTemplateReport({ id }).catch((err) =>
                          delErrorCheck(err),
                        ),
                      ),
                    )

                    clearSelectedRows()
                    refreshTableData()

                    Alerts.success(`Reports deleted`)
                  } catch (err) {
                    Alerts.error(
                      'There was a problem deleting Reports',
                      err.message,
                    )

                    console.log('delete() err', err)
                  }
                }}
                size="sm"
                subtle
                title="Delete Reports"
                variant="critical"
              />
            </>
          ) : null
        }
      </Inline>

      <Button onClick={onClickNewTemplateReport} variant="main">
        New Report
      </Button>
    </Inline>
  )
}

export { TemplateReportTableControls }
