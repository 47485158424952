export const SITES_ROUTES = {
  __ROOT__: ['/sites', '/site'],
  ALL_SITES: '/sites',
  EDIT_SITE: '/site/edit/:siteId',
  SITE_TEMPLATES: '/site/templates/:siteId',
  NEW_SITE: '/site/new',
  VIEW_SITE: '/site/view/:siteId',
  VIEW_SITE_STATS: '/site/stats/:siteId',
  VIEW_SITE_INTERACTIVE_REPORT: '/site/interactive-report/:siteId',

}
