import React, { useEffect, useState } from 'react'
import {
  Comment,
  Completion,
} from '@src/generatedClient/generated/taskreportsApi'
import { format } from 'date-fns'
import { Button, HorizontalScroll, Image } from '@therms/atalaya'
import { findComments } from '../../ViewSite/components/SinglePagePdf'
import { Comments } from '@src/modules/Comments/shared/Comments'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

interface ActivityDetailProps {
  activity: Completion
  comments: Comment[]
  siteId: string
}

function ActivityDetail({ activity, comments, siteId }: ActivityDetailProps) {
  const [images, setImages] = useState<string[]>([])

  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const imageUrls = findComments(activity.commentIds, comments)
      .filter((comment) =>
        comment.fileUrls?.some((url) => url.toLowerCase().endsWith('.jpg')),
      )
      .map(
        (comment) =>
          comment.fileUrls.filter((url) =>
            url.toLowerCase().endsWith('.jpg'),
          ) as string[],
      )
      .flat()

    setImages(imageUrls)
  }, [activity, comments])

  if (!activity) return null

  return (
    <div>
      <p>{activity.description}</p>

      {activity.completedAtTime && (
        <p>Completed at: {format(activity.completedAtTime, 'MMM d, yyyy')}</p>
      )}

      <p>
        Status: {activity.isComplete && 'Complete'}{' '}
        {activity.isSkipped && 'Skipped'}{' '}
        {!activity.isComplete && !activity.isSkipped && 'Missed'}
      </p>

      {images?.length > 0 && (
        <div className="relative w-full mt-3">
          <Image
            src={images[currentImageIndex]}
            key={images[currentImageIndex]}
            alt={`Image ${currentImageIndex + 1}`}
            className="rounded-lg !w-full flex-1 aspect-square object-fill"
          />

          {images.length > 1 && (
            <div className="absolute z-50 top-1/2 right-0 left-0 flex justify-between w-full items-center">
              <Button
                onClick={() =>
                  setCurrentImageIndex(
                    (currentImageIndex - 1 + images.length) % images.length,
                  )
                }
                outline
                variant="neutral"
                className="!px-4 !py-1 !bg-gray-500"
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </Button>
              <Button
                onClick={() =>
                  setCurrentImageIndex((currentImageIndex + 1) % images.length)
                }
                outline
                variant="neutral"
                className="!px-4 !py-1 !bg-gray-500"
              >
                <ChevronRightIcon className="h-5 w-5" />
              </Button>
            </div>
          )}

          <p className="text-sm text-center mt-2 text-gray-500">
            Image {currentImageIndex + 1} of {images.length}
          </p>
        </div>
      )}

      <Comments
        siteId={siteId}
        commentIds={activity.commentIds}
        className="space-y-4 divide-y divide-neutral-300"
        hideImages
      />
    </div>
  )
}

export { ActivityDetail }
