import React, { useCallback } from 'react'
import { FormLayout } from '@therms/atalaya'
import { Formik } from 'formik'
import { FormikText } from '@src/components/FormikFields/FormikText'
import * as Yup from 'yup'
import { SelectTask } from '@src/modules/Tasks/shared/SelectTask'
import {
  createTemplateReport,
  modifyTemplateReport,
  TemplateReport,
} from '@src/generatedClient/generated/taskreportsApi'
import { Alerts } from '@src/components/Alerts'
import { useAuth } from '@src/modules/Auth/shared/state'

const ValidationSchema = Yup.object().shape({
  name: Yup.string().min(3).required('A name is required'),
})

type Props = {
  onComplete: () => void
  templateReport: TemplateReport
}

function TemplateReportForm({ onComplete, templateReport }: Props) {
  const { currentSite } = useAuth()
  const save = useCallback(async (values) => {
    try {

      if (templateReport) {
        await modifyTemplateReport({
          id: templateReport.id,
          name: values.name,
          taskIds: values.taskIds,
        })
      } else {
        await createTemplateReport({
          name: values.name,
          taskIds: values.taskIds,
          siteId: currentSite.id,
        })
      }

      Alerts.success(`Report ${templateReport ? 'updated' : 'created'}`)
      onComplete()
    } catch (err) {
      Alerts.error(
        'There was a problem saving the Report',
        err.message,
      )
      console.log('save() err', err)
    }
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={
        templateReport
          ? { taskIds: templateReport.taskIds, name: templateReport.name }
          : {}
      }
      onSubmit={save}
      validationSchema={ValidationSchema}
    >
      {({ isValid, submitForm, isSubmitting, setFieldValue, values }) => (
        <FormLayout dividers="space" space="xs">
          <FormikText label="Report Name" name="name" />
          <FormLayout.Section heading="Select Tasks">
            <SelectTask
              isClearable
              multi
              placeholder="Task..."
              name="taskIds"
              onChangeValue={(taskIds) => setFieldValue('taskIds', taskIds)}
              value={values.taskIds}
            />
          </FormLayout.Section>

          <FormLayout.Controls
            disabled={!isValid}
            isSaving={isSubmitting}
            onSave={submitForm}
          />
        </FormLayout>
      )}
    </Formik>
  )
}

export { TemplateReportForm }
