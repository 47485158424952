import { buildMemoryStorage, setupCache } from 'axios-cache-interceptor'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { WALLBOARD_JWT_KEY } from '@src/modules/wallboard/constants'
import { Alerts } from '@src/components/Alerts'

export const isProd = process.env.NODE_ENV === 'production'

// Set up the cached axios instance as before
export const apiClient = setupCache(
  axios.create({
    baseURL: isProd ? '/api' : 'https://devadmin.quicktask.us/api', // 'http://localhost:3000/api'
  }),
  {
    storage: buildMemoryStorage(),
    ttl: 60,
  },
)

// Add the interceptors
apiClient.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${
      localStorage.getItem('jwt') || localStorage.getItem(WALLBOARD_JWT_KEY)
    }`
    return config
  },
  (error) => Promise.reject(error),
)

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('jwt')
      localStorage.getItem(WALLBOARD_JWT_KEY)
      document.location.reload()
    }
    return Promise.reject(error)
  },
)

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 423) {
      Alerts.error('This is part of a locked template.  It cannot be modified.')
    }
    return Promise.reject(error)
  },
)

export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  const source = axios.CancelToken.source()
  const promise = apiClient({
    ...config,
    ...options,
    cancelToken: source.token,
  })

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

export type ErrorType<Error> = AxiosError<Error>

export type BodyType<BodyData> = BodyData
