import { Alerts } from '@src/components/Alerts'
import { FormikText } from '@src/components/FormikFields/FormikText'
import { cloneTask } from '@src/generatedClient/generated/taskreportsApi'
import { useAuth } from '@src/modules/Auth/shared/state'
import { Button } from '@therms/atalaya'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'

type Props = {
  taskId: string
  onSuccess: () => void
}

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2)
    .required('Title must be at least 2 characters long'),
})

function CloneTaskForm({ taskId, onSuccess }: Props) {
  const { currentSite } = useAuth()
  const save = async (values) => {
    try {
      await cloneTask({
        newTitle: values.title,
        taskId,
        siteId: currentSite.id,
      })

      Alerts.success('Task cloned')

      onSuccess()
    } catch (err) {
      Alerts.error('There was a problem cloning the Task', err.message)

      console.log('save() err', err)
    }
  }

  if (!taskId) {
    return null
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: '',
      }}
      onSubmit={save}
      validateOnChange={false}
      validationSchema={ValidationSchema}
    >
      {({ isValid, submitForm, isSubmitting }) => (
        <div className="space-y-5">
          <FormikText label="New Title" name="title" placeholder="Title" />
          <div className="flex justify-center">
            <Button
              disabled={!isValid}
              loading={isSubmitting}
              onClick={submitForm}
              variant="main"
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </Formik>
  )
}

CloneTaskForm.defaultProps = {}

export { CloneTaskForm }
