import React, { useRef, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  DateTime,
  FormLayout,
  Image,
  Inline,
  Label,
  Loading,
  Modal,
  Overlay,
} from '@therms/atalaya'
import { ResolveEsOccurrence } from '@src/services/api-client'
import { EscalationName } from '@src/modules/Escalations/shared/EscalationName'
import { UserName } from '@src/modules/Users/shared/UserName'
import { TaskName } from '@src/modules/Tasks/shared/TaskName'
import { FiCheck, FiCheckCircle, FiUpload, FiX } from 'react-icons/fi'
import { Alerts } from '@src/components/Alerts'
import { Comments } from '@src/modules/Comments/shared/Comments'
import { EscalationOccurrenceForwardToContactModal } from '@src/modules/Escalations/router/screens/Index/components/EscalationOccurrences/EscalationOccurrenceForwardToContactModal'
import {
  createComment,
  EscOccurrenceList,
  getFileUploadUrl,
  MultiChoiceResponse,
  PicUploadFileTypes,
} from '@src/generatedClient/generated/taskreportsApi'
import { MultiChoiceResponseForm } from '@src/modules/Escalations/router/screens/Index/components/EscalationOccurrences/MultiChoiceResponseForm'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useRemoteStorage } from '@src/hooks/useRemoteStorage'
import Dropzone from 'react-dropzone'

const extractFileExtension = (fileName: string) => {
  const parts = fileName.split('.')
  return parts[parts.length - 1]
}

interface EscalationOccurrenceModalProps {
  closeHandler: () => void
  escOccurrence: EscOccurrenceList['escOccurrences'][number]
}

type Attachment = {
  key: string
  name: string
  uri: string
}

function EscalationOccurrenceModal({
  closeHandler,
  escOccurrence,
}: EscalationOccurrenceModalProps) {
  const [loading, setLoading] = useState(false)
  const [showForwardModal, setShowForwardModal] = useState(false)
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const [isImageUploading, setIsImageUploading] = useState(false)

  const formikRef = useRef(null)

  const { storageUploader } = useRemoteStorage()

  const handleResolve = async (values = {}) => {
    setLoading(true)

    try {
      const answersPayload =
        Object.keys(values).length > 0
          ? {
              multiChoiceResponses: Object.entries(values).reduce(
                (acc, [key, value]) => {
                  acc.push({
                    questionKey: key,
                    answerKey: value as string,
                  })
                  return acc
                },
                [] as MultiChoiceResponse[],
              ),
            }
          : {}

      // Create attachment comments
      if (attachments.length > 0) {
        await createComment({
          fileKeys: attachments.map((attachment) => attachment.key),
          attachTarget: {
            attachType: 'escOccurrence',
            id: escOccurrence.id,
          },
          siteId: escOccurrence.siteId,
          userNote: '',
        })
      }

      await ResolveEsOccurrence({
        siteId: escOccurrence.siteId,
        // escOccurrenceId: escOccurrence.id,
        escOccuranceId: escOccurrence.id,
        adminNote: '',
        ...answersPayload,
      })
      closeHandler()
      Alerts.success('Escalation issue resolved')
    } catch (err) {
      Alerts.error('Error resolving this issue, please try again', err.message)
    } finally {
      setLoading(false)
    }
  }

  const ValidationSchema = Yup.object().shape(
    escOccurrence.multiChoice?.reduce((acc, question) => {
      acc[question.key] = Yup.string().required('Required')
      return acc
    }, {}),
  )

  const handleAttachment = async (files: File[]) => {
    if (!files?.length) {
      return null
    }

    try {
      setIsImageUploading(true)
      const fileAttachments = []
      await Promise.all(
        Array.from(files).map(async (file) => {
          if (file.type.indexOf('image') === -1) {
            Alerts.error('Please only upload an image')
            return null
          }
          // Get file upload url
          const {
            data: { key, url },
          } = await getFileUploadUrl({
            completionId: escOccurrence.triggeredBy.triggerCompletionId || '',
            fileExtension: PicUploadFileTypes[extractFileExtension(file.name)],
          })

          // Upload file
          await storageUploader(
            url,
            file,
            () => {
              // Add key to attachments
              fileAttachments.push({
                key,
                name: file.name,
                uri: URL.createObjectURL(file),
              })
            },
            () => {
              throw new Error()
            },
          )

          return null
        }),
      )

      setAttachments([...attachments, ...fileAttachments])
      return null
    } catch (error) {
      Alerts.error('Error uploading file', error.message)
      return null
    } finally {
      setIsImageUploading(false)
    }
  }

  const acceptedFileTypes = Object.values(PicUploadFileTypes).map(
    (ext) => `.${ext}`,
  )

  return (
    <Modal
      closeHandler={closeHandler}
      escPressClose
      header={
        escOccurrence.resolvedDate ? (
          <Inline alignX="between" width="full">
            <span>Escalation Issue</span>

            <div className="text-sm font-light">
              <span className="inline-block">
                <FiCheckCircle color="green" size={14} />
              </span>
              Resolved by{' '}
              <UserName className="italic" id={escOccurrence.resolvingUserId} />
              (<DateTime timestamp={escOccurrence.resolvedDate} />)
            </div>
          </Inline>
        ) : (
          <Inline alignX="between" width="full">
            <span>Escalation Issue</span>

            <ButtonGroup size="sm">
              <Button
                onClick={() => setShowForwardModal(true)}
                variant="main"
                subtle
              >
                Email Issue to Contact
              </Button>
              <Button
                onClick={() =>
                  formikRef.current?.submitForm() ?? handleResolve({})
                }
                variant="positive"
              >
                <FiCheck color="#fff" size={14} />
                Resolve
              </Button>
            </ButtonGroup>
          </Inline>
        )
      }
    >
      {loading && <Loading overlay />}
      <div>
        <Label>Date</Label> <DateTime timestamp={escOccurrence.timestamp} />
      </div>

      <div>
        <div>
          <Label>Escalation</Label>{' '}
          <EscalationName id={escOccurrence.escalationId} />
        </div>

        <div>
          <Label>Escalated By</Label>{' '}
          <span className="font-light">
            <UserName className="italic" id={escOccurrence.escalatingUserId} />
          </span>
        </div>
      </div>

      <div>
        <div>
          <Label>Trigger</Label>{' '}
          <TaskName
            id={escOccurrence.triggeredBy.triggerId}
            render={(task) => {
              const taskGroup = task.taskGroups[0]

              return (
                <span>
                  Task{' '}
                  {taskGroup?.name && (
                    <span
                      style={
                        taskGroup?.uiData?.color
                          ? { color: taskGroup.uiData.color }
                          : {}
                      }
                    >
                      {taskGroup?.name}{' '}
                    </span>
                  )}
                  <span>{task.title}</span>
                </span>
              )
            }}
          />
        </div>
      </div>

      <div className="mb-5">
        <Label>User Note</Label>
        <Comments
          className="space-y-4 divide-y divide-neutral-300"
          commentIds={escOccurrence.commentIds}
          siteId={escOccurrence.siteId}
        />
      </div>

      {escOccurrence.triggeredBy?.triggerCompletionId &&
        !escOccurrence.resolvedDate && (
          <div className="mb-5">
            <FormLayout.Section
              heading="Image Attachement"
              subTitle="Please attach any pictures/images needed to document this resolution"
            >
              <Dropzone
                accept={{ acceptedFileTypes }}
                multiple
                onDrop={handleAttachment}
                disabled={isImageUploading}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <section className="relative">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Card className="w-full border border-dashed border-neutral-300 flex rounded-2xl flex-col items-center justify-center p-4 relative">
                        <FiUpload size={30} />
                        <p>Drag & Drop Files Here</p>
                        <p className="font-medium">OR</p>
                        <Button className="w-max ">Browse Files</Button>
                      </Card>
                      {(isDragActive || isImageUploading) && (
                        <Overlay>
                          {isDragActive && <p>Drop the files here</p>}

                          {isImageUploading && <Loading />}
                        </Overlay>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>

              {attachments.length > 0 && (
                <div className="mt-4">
                  <Inline wrap space="base">
                    {attachments.map((attachment) => (
                      <div className="relative" key={attachment.key}>
                        <Image
                          key={attachment.key}
                          src={attachment.uri}
                          alt={attachment.name}
                          className="w-32 aspect-video object-cover rounded-lg"
                        />

                        <Button
                          className="absolute !rounded-full -top-0 right-0 -translate-y-1/2 translate-x-1/2"
                          variant="neutral"
                          size="sm"
                          onClick={() =>
                            setAttachments(
                              attachments.filter(
                                (att) => att.key !== attachment.key,
                              ),
                            )
                          }
                        >
                          <FiX size={20} />
                        </Button>
                      </div>
                    ))}
                  </Inline>
                </div>
              )}
            </FormLayout.Section>
          </div>
        )}

      {!escOccurrence.resolvedDate &&
        escOccurrence?.multiChoice?.length > 0 && (
          <Formik
            enableReinitialize
            initialValues={escOccurrence.multiChoice.reduce((acc, question) => {
              acc[question.key] = question.options?.length
                ? question.options[0].key
                : ''
              return acc
            }, {})}
            onSubmit={handleResolve}
            innerRef={formikRef}
            validationSchema={ValidationSchema}
          >
            <FormLayout.Section
              heading="Resolution Questions"
              subTitle="Please answer these questions to resolve this issue"
            >
              <MultiChoiceResponseForm
                multiChoices={escOccurrence.multiChoice}
              />
            </FormLayout.Section>
          </Formik>
        )}

      {showForwardModal && (
        <EscalationOccurrenceForwardToContactModal
          escOccurrence={escOccurrence}
          onClose={() => setShowForwardModal(false)}
        />
      )}
    </Modal>
  )
}

EscalationOccurrenceModal.defaultProps = {}

export { EscalationOccurrenceModal }
