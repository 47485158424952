import { MultiChoiceQuestion } from '@src/generatedClient/generated/taskreportsApi'
import React from 'react'
import { FormikHTMLSelect } from '@src/components/FormikFields/FormikHTMLSelect'
import { FormikText } from '@src/components/FormikFields/FormikText'

type Props = {
  multiChoices: MultiChoiceQuestion[]
}

function MultiChoiceResponseForm({ multiChoices }: Props) {
  return (
    <div>
      {multiChoices.map((question) => (
        <div key={question.key}>
          {question.options?.length > 0 ? (
            <FormikHTMLSelect
              label={question.question}
              name={question.key}
              options={question.options.map((option) => ({
                value: option.key,
                label: option.display,
              }))}
            />
          ) : (
            <FormikText label={question.question} name={question.key} />
          )}
        </div>
      ))}
    </div>
  )
}

export { MultiChoiceResponseForm }
