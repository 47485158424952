import React from 'react'
import { EscOccurrence } from '@src/generatedClient/generated/taskreportsApi'
import { DateTime, Label } from '@therms/atalaya'
import { EscalationName } from '@src/modules/Escalations/shared/EscalationName'
import { UserName } from '@src/modules/Users/shared/UserName'
import { TaskName } from '@src/modules/Tasks/shared/TaskName'
import { Comments } from '@src/modules/Comments/shared/Comments'

interface EscalationOccurrenceDetailProps {
  escOccurrence: EscOccurrence
}

function EscalationOccurrenceDetail({
  escOccurrence,
}: EscalationOccurrenceDetailProps) {
  return (
    <div>
      <div>
        <Label>Date</Label> <DateTime timestamp={escOccurrence.timestamp} />
      </div>

      <div>
        <div>
          <Label>Escalation</Label>{' '}
          <EscalationName id={escOccurrence.escalationId} />
        </div>

        <div>
          <Label>Escalated By</Label>{' '}
          <span className="font-light">
            <UserName className="italic" id={escOccurrence.escalatingUserId} />
          </span>
        </div>
      </div>

      <div>
        <div>
          <Label>Trigger</Label>{' '}
          <TaskName
            id={escOccurrence.triggeredBy.triggerId}
            render={(task) => {
              const taskGroup = task.taskGroups[0]

              return (
                <span>
                  Task{' '}
                  {taskGroup?.name && (
                    <span
                      style={
                        taskGroup?.uiData?.color
                          ? { color: taskGroup.uiData.color }
                          : {}
                      }
                    >
                      {taskGroup?.name}{' '}
                    </span>
                  )}
                  <span>{task.title}</span>
                </span>
              )
            }}
          />
        </div>
      </div>

      <div className="mb-5">
        <Label>User Note</Label>
        <Comments
          className="space-y-4 divide-y divide-neutral-300"
          commentIds={escOccurrence.commentIds}
          siteId={escOccurrence.siteId}
        />
      </div>
    </div>
  )
}

EscalationOccurrenceDetail.defaultProps = {}

export { EscalationOccurrenceDetail }
