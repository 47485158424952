import React, { useCallback, useState } from 'react'
import { Card, FormLayout, Loading } from '@therms/atalaya'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Alerts } from '@src/components/Alerts'
import { FormikText } from '@src/components/FormikFields/FormikText'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import {
  CreateEscalation,
  DeleteEscalation,
  GetEscalation,
  ModifyEscalation,
} from '@src/services/api-client'
import { delErrorCheck } from '@src/services/api-resp-checks'
import { FormikTextarea } from '@src/components/FormikFields/FormikTextarea'
import { FormikSelectPositions } from '@src/modules/Positions/shared/Fields/FormikSelectPositions'
import { useAuth } from '@src/modules/Auth/shared/state'
import { FormikSelect } from '@src/components/FormikFields/FormikSelect'
import { MultipleChoiceFormField } from '@src/modules/Tasks/router/screens/TaskForm/components/FormSections/components/MultipleChoiceFormField'

const ValidationSchema = Yup.object().shape({
  name: Yup.string().min(3).required('A name is required'),
})

function EscalationForm({ onCancel, onComplete, escalationId }) {
  const [isDeleting, setIsDeleting] = useState(false)
  const { currentSite } = useAuth()
  const { data, isCalling } = useApiWithSiteId(
    GetEscalation,
    escalationId || false,
  )

  const handleDelete = async () => {
    setIsDeleting(true)

    DeleteEscalation(escalationId)
      .catch((err) => delErrorCheck(err))
      .finally(() => onComplete())
  }

  const save = useCallback(async (values) => {
    try {
      if (escalationId) {
        await ModifyEscalation({
          details: values.details || '',
          name: values.name,
          id: values.id,
          notifyPositionIds: values.notifyPositionIds,
          pushTypes: values.pushTypes || [],
          multiChoice: values.multiChoice || [],
        })
      } else {
        await CreateEscalation({
          details: values.details || '',
          name: values.name,
          siteId: currentSite.id,
          notifyPositionIds: values.notifyPositionIds,
          pushTypes: values.pushTypes || [],
          multiChoice: values.multiChoice || [],
        })
      }

      Alerts.success(`Escalation ${escalationId ? 'updated' : 'created'}`)

      onComplete()
    } catch (err) {
      Alerts.error('There was a problem saving the Escalation', err.message)

      console.log('save() err', err)
    }
  }, [])

  const escalation = data || {}
  const isTemplate = false

  return (
    <Formik
      enableReinitialize
      initialValues={escalation}
      onSubmit={save}
      validationSchema={ValidationSchema}
    >
      {({ isValid, submitForm, isSubmitting, values }) => (
        <FormLayout dividers="space" space="xs">
          {isCalling && <Loading overlay size="xxl" />}

          {(isDeleting || isSubmitting) && <Loading overlay size="xxl" />}

          {isTemplate && (
            <Card className="bg-info">
              This Escalation is from a template and cannot be edited at this
              time.
            </Card>
          )}

          <FormikText label="Escalation Name" name="name" />

          <FormikTextarea hint="optional" label="Description" name="details" />

          <FormikSelectPositions
            label="Positions to Notify"
            multi
            name="notifyPositionIds"
          />

          {!!values.notifyPositionIds?.length && (
            <FormikSelect
              label="Notify Positions Option"
              multi
              name="pushTypes"
              options={[
                { label: 'Push-Notification', value: 'pushNotification' },
                { label: 'Email', value: 'email' },
              ]}
            />
          )}

          <FormLayout.Section
            heading="Questions for Resolver:"
            subTitle="When this Escalation is Resolved, the resolver must answer these questions"
          >
            <MultipleChoiceFormField />
          </FormLayout.Section>

          <FormLayout.Controls
            disabled={!isValid}
            isSaving={isSubmitting}
            onCancel={onCancel}
            onDelete={escalationId && handleDelete}
            onSave={submitForm}
          />
        </FormLayout>
      )}
    </Formik>
  )
}

EscalationForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  escalationId: PropTypes.string,
}

export { EscalationForm }
