/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Inline,
  Loading,
  Modal,
  TableGroup,
} from '@therms/atalaya'
import {
  allOrgTemplates,
  getTemplateReportDailyData,
  getTemplateReports,
  OrgTemplateDisplay,
  TemplateReport,
} from '@src/generatedClient/generated/taskreportsApi'
import { SequentialPDFGenerator } from '@src/modules/Sites/router/screens/ViewSite/components/TemplateReportGenerator'
import { useHistory } from 'react-router-dom'
import { TemplateReportForm } from '../../TemplateForm/components/TemplateReportForm'
import { TemplateReportTableControls } from './TemplateReportTableControls'

interface DateRangeFormProps {
  siteId: string
  templateReportId: string
}

function DateRangeForm({ siteId, templateReportId }: DateRangeFormProps) {
  const history = useHistory()

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  // Generate array of ISO timestamps for each day in the range
  const dateArguments = useMemo(() => {
    if (!startDate || !endDate) return []

    const dates = []
    const start = new Date(startDate)
    const end = new Date(endDate)

    // Set times to midnight UTC to ensure consistent day boundaries
    start.setUTCHours(0, 0, 0, 0)
    end.setUTCHours(0, 0, 0, 0)

    let currentDate = start
    while (currentDate <= end) {
      dates.push(currentDate.toISOString())
      // Add 24 hours
      currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000)
    }

    return dates
  }, [startDate, endDate])

  const fetchFunction = async (d: string) => {
    const data = await getTemplateReportDailyData({
      siteId,
      startDate: d,
      templateReportId,
    })
    return { date: d, data }
  }

  const handleInteractiveReport = () => {
    history.push(
      `/site/interactive-report/${siteId}?startDate=${startDate}&endDate=${endDate}&trId=${templateReportId}`,
    )
  }

  return (
    <div className="p-4 space-y-4">
      <div className="space-y-2">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="block">
          Start Date
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="block w-full mt-1 border rounded-md p-2"
          />
        </label>
      </div>

      <div className="space-y-2">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="block">
          End Date
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="block w-full mt-1 border rounded-md p-2"
            min={startDate} // Prevent selecting end date before start date
          />
        </label>
      </div>

      <div className="flex justify-end space-x-2 pt-4">
        <div className="py-4">
          <button
            onClick={handleInteractiveReport}
            type="button"
            disabled={!startDate || !endDate}
            className="px-4 py-2 rounded  bg-gray-300"
          >
            Interactive Report
          </button>
        </div>
        <SequentialPDFGenerator
          fetchFunction={fetchFunction}
          arguments={dateArguments}
        />
      </div>
    </div>
  )
}

function TemplateReportHeadRow() {
  return (
    <TableGroup.HeadRow canSelect>
      <TableGroup.HeadCell>Report Name</TableGroup.HeadCell>
      <TableGroup.HeadCell>Template</TableGroup.HeadCell>
      <TableGroup.HeadCell>Actions</TableGroup.HeadCell>
    </TableGroup.HeadRow>
  )
}

interface TableRowProps {
  // eslint-disable-next-line react/no-unused-prop-types
  data: TemplateReport[]
  rowData: TemplateReport
  // eslint-disable-next-line react/no-unused-prop-types
  rowIndex: number
  rowKey: string
  templateMap: Map<string, string>
  onEditClicked: () => void
}

function TemplateReportRow({
  rowData,
  rowKey,
  templateMap,
  onEditClicked,
}: TableRowProps) {
  return (
    <TableGroup.Row
      className="hover:bg-surface hover:cursor-pointer"
      rowKey={rowKey}
      canSelect
    >
      <TableGroup.Cell>{rowData.name}</TableGroup.Cell>
      <TableGroup.Cell>
        {rowData.templateIds?.map((templateId, i) => (
          <React.Fragment key={templateId}>
            {templateMap.get(templateId) || 'Unknown Template'}
            {i + 1 < rowData.templateIds.length ? ', ' : ''}
          </React.Fragment>
        ))}
      </TableGroup.Cell>
      <TableGroup.Cell>
        <Inline space="xs">
          <Button
            variant="neutral"
            onClick={(e) => {
              e.stopPropagation()
              onEditClicked?.()
            }}
          >
            Edit
          </Button>
        </Inline>
      </TableGroup.Cell>
    </TableGroup.Row>
  )
}

interface TemplateReportListProps {
  siteId: string
}

export function TemplateReportList({ siteId }: TemplateReportListProps) {
  const [reports, setReports] = useState<TemplateReport[]>([])
  const [templateMap, setTemplateMap] = useState<Map<string, string>>(new Map())
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [selectedReportId, setSelectedReportId] = useState<string | null>(null)
  const [showTemplateFormModal, setShowTemplateFormModal] =
    useState<boolean>(false)
  const [editTemplateReport, setEditTemplateReport] = useState<TemplateReport>()

  // Fetch template reports
  const fetchReports = async () => {
    try {
      setLoading(true)
      const response = await getTemplateReports({ siteId })
      setReports(response.data)
      setError(null)
    } catch (err) {
      setError('Failed to fetch reports')
      console.error('Error fetching reports:', err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchReports()
  }, [siteId])

  // Fetch templates and create mapping
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await allOrgTemplates({ site: siteId })
        const newTemplateMap = new Map<string, string>()
        response.data.orgTemplates.forEach((template: OrgTemplateDisplay) => {
          newTemplateMap.set(template.id, template.name)
        })
        setTemplateMap(newTemplateMap)
      } catch (err) {
        console.error('Error fetching reports:', err)
      }
    }

    fetchTemplates()
  }, [siteId])

  if (error) {
    return <div className="p-4 text-red-600">{error}</div>
  }

  function RowWithTemplateMap(props: Omit<TableRowProps, 'templateMap'>) {
    return (
      <TemplateReportRow
        {...props}
        templateMap={templateMap}
        onEditClicked={() => {
          setEditTemplateReport({ ...props }.rowData)
          setShowTemplateFormModal(true)
        }}
      />
    )
  }

  return (
    <div className="relative">
      <Loading overlay visible={loading && !reports.length} />

      <TableGroup
        data={reports}
        keyProp="id"
        HeadRow={TemplateReportHeadRow}
        Row={RowWithTemplateMap}
        onClickRow={({ rowData }) => setSelectedReportId(rowData.id)}
        Controls={
          <TemplateReportTableControls
            onClickNewTemplateReport={() => setShowTemplateFormModal(true)}
            refreshTableData={fetchReports}
          />
        }
      />

      {selectedReportId && (
        <Modal
          closeHandler={() => setSelectedReportId(null)}
          header="Select Date Range"
        >
          <DateRangeForm siteId={siteId} templateReportId={selectedReportId} />
        </Modal>
      )}

      {showTemplateFormModal && (
        <Modal
          closeHandler={() => setShowTemplateFormModal(false)}
          header={editTemplateReport ? 'Modify Report' : 'New Report'}
        >
          <TemplateReportForm
            onComplete={() => {
              setShowTemplateFormModal(false)
              fetchReports()
            }}
            templateReport={editTemplateReport}
          />
        </Modal>
      )}
    </div>
  )
}
