import imageCompression from 'browser-image-compression'

// Function for fetching an image from a url
export const fetchImage = async (url: string) => {
  try {
    const response = await fetch(url)

    const blob = await response.blob()

    return new File([blob], 'image.jpg', { type: 'image/jpeg' })
  } catch (error) {
    throw new Error('Failed to fetch image')
  }
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)

    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

// Function for compressing the image
export const compressImage = async (url: string) => {
  const options = {
    maxSizeMB: 0.05,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  }
  try {
    const imageFile = await fetchImage(url)
    if (!imageFile) {
      return ''
    }

    const compressedFile = await imageCompression(imageFile, options)

    const base64Version = await blobToBase64(compressedFile)

    return base64Version
  } catch (error) {
    throw new Error('Failed to compress image')
  }
}
