import React from 'react'
// import PropTypes from 'prop-types'
import { MdInfo, MdNfc } from 'react-icons/md'
import { Columns, FormLayout, Modal, Select } from '@therms/atalaya'
import { FormikText } from '@src/components/FormikFields/FormikText'
import { useFormikContext } from 'formik'
import { FormikCheckbox } from '@src/components/FormikFields/FormikCheckbox'
import { MissedActionFormField } from '@src/modules/Tasks/router/screens/TaskForm/components/FormSections/components/MissedActionFormField'
import { CompletedActionFormField } from './components/CompletedActionFormField '
import { FormikSelectPositions } from '@src/modules/Positions/shared/Fields/FormikSelectPositions'
import { TaskSchedule } from '@src/modules/Tasks/router/screens/TaskForm/components/FormSections/components/TaskSchedule'
import { SelectTaskGroup } from '@src/modules/Tasks/shared/SelectTaskGroup'
import { SelectEscalation } from '@src/modules/Escalations/shared/SelectEscalation'
import { FormikHTMLSelect } from '@src/components/FormikFields/FormikHTMLSelect'
import { TaskModifyTaskRequestBody } from '@src/services/api-client'
import { PrintTaskQRCode } from '@src/modules/Tasks/router/screens/TaskForm/components/FormSections/components/PrintTaskQRCode'
import { ButtonWithModal } from '@src/components/ButtonWithModal'
import { WriteTaskNFCTag } from '@src/modules/Tasks/router/screens/TaskForm/components/FormSections/components/WriteTaskNFCTag'
import { useCurrentOrganization } from '@src/modules/Auth/shared/useCurrentOrganization'
import { MultipleChoiceFormField } from '@src/modules/Tasks/router/screens/TaskForm/components/FormSections/components/MultipleChoiceFormField'

function TaskInfoFormSection() {
  const form = useFormikContext<TaskModifyTaskRequestBody>()

  const { isTemplateProducer } = useCurrentOrganization()

  return (
    <FormLayout dividers="space">
      {isTemplateProducer && (
        <FormLayout.Section heading="Template">
          <FormikCheckbox
            label="This Task is a template"
            name="orgTemplateData.orgTemplateProducerEnable"
            subText="This Task will be included in the list of Tasks for downstream Organizations"
          />
        </FormLayout.Section>
      )}

      <FormLayout.Section heading="Info">
        <SelectTaskGroup
          isClearable
          label="Group"
          multi={false}
          name="taskGroupIds"
          onChangeValue={(taskGroupId) =>
            form.setFieldValue('taskGroupIds', taskGroupId ? [taskGroupId] : [])
          }
          value={form.values.taskGroupIds?.[0]}
        />

        <FormikText label="Title" name="title" />

        <FormikText label="Description" name="description" />

        <FormikSelectPositions label="Positions" multi name="positionIds" />

        <FormikHTMLSelect
          label="Difficulty Rating"
          name="difficulty"
          options={[
            { value: 'easy', label: 'Easy' },
            { value: 'medium', label: 'Medium' },
            { value: 'hard', label: 'Hard' },
          ]}
          subText="This rating is used for weighted rankings of employee Task completion history"
        />
      </FormLayout.Section>

      <FormLayout.Divider withBorder />

      <FormLayout.Section
        heading="Task Reminders"
        subTitle="Configure reminders for the Position(s) that this Task is assigned to"
      >
        <MissedActionFormField actionType="reminder" />
      </FormLayout.Section>

      <FormLayout.Divider withBorder />

      <TaskSchedule />

      <FormLayout.Divider withBorder />
      <FormLayout.Section heading="Escalations">
        <SelectEscalation
          label="Allowed Escalation Types"
          multi
          onChangeValue={(value) => form.setFieldValue('escalationIds', value)}
          value={form.values.escalationIds}
          subText="Escalations allow the employee who is completing the Task to raise an issue or concern. Select any Escalation Types that should be allowed when completing this Task.."
        />
      </FormLayout.Section>

      <FormLayout.Divider withBorder />
      <FormLayout.Section
        heading="Questions"
        subTitle="Add Questions to the Task"
        horizontal
      >
        <div>
          <Columns space="base" alignX="end">
            <Columns.Column width="1/12">
              <div className="relative inline-block m-4 group">
                <MdInfo className="w-5 h-5 text-gray-400 cursor-help" />

                <div className="absolute top-0 z-10 invisible w-64 p-4 transition-opacity bg-white border border-gray-200 rounded-lg shadow-lg opacity-0 group-hover:visible right-6 group-hover:opacity-100">
                  <h3 className="mb-2 text-lg font-semibold">Info</h3>
                  <p className="text-sm text-gray-600">
                    All questions added to a task MUST be completed for the task
                    to be completed. If a question has options, it will be
                    displayed as a multiple choice questions. If not, users can
                    write their own answer. Answers from the questions will be
                    shown in the comment section on the task activity feed.
                  </p>
                </div>
              </div>
            </Columns.Column>
          </Columns>
        </div>

        <MultipleChoiceFormField />
      </FormLayout.Section>

      <FormLayout.Divider withBorder />

      <FormLayout.Section
        heading="Verification"
        subTitle="Optionally require the User to verify that this Task was completed"
      >
        <FormikCheckbox
          label="Allow this Task to be skipped by the User"
          name="canSkip"
        />

        <div className="mb-sm">
          <Select
            label="Method"
            options={[
              { label: 'No Verification Required', value: 'selfReport' },
              { label: 'NFC Tag Scan', value: 'nfc' },
              { label: 'NFC Tag Scan w/ Photo', value: 'nfcAndPicture' },
              { label: 'QR Code Scan', value: 'qrCode' },
              { label: 'Photo', value: 'picture' },
            ]}
            onChangeValue={(value) => form.setFieldValue('verification', value)}
            value={form.values.verification}
          />

          {(form.values.verification === 'nfc' ||
            form.values.verification === 'nfcAndPicture') && (
            <div className="rounded bg-info p-sm">
              <div className="flex mb-xs">
                NFC verification requires that you setup a NFC tag with the
                mobile app.
              </div>

              <div>
                {!form.values.id && (
                  <strong>
                    <em>
                      Finish saving this Task then you will be prompted to
                      configure the NFC tag.
                    </em>
                  </strong>
                )}

                <ButtonWithModal
                  modalElement={
                    <Modal header="Configure NFC Tag">
                      <WriteTaskNFCTag task={form.values} />
                    </Modal>
                  }
                  disabled={!form.values.id}
                  variant="main"
                >
                  <MdNfc size="22" /> Configure NFC Tag
                </ButtonWithModal>
              </div>
            </div>
          )}

          {form.values?.verification === 'qrCode' && (
            <div>
              {form.values.id ? (
                <PrintTaskQRCode task={form.values} />
              ) : (
                <div className="text-sm text-color-neutral">
                  <em>Create the task to print the QR code</em>
                </div>
              )}
            </div>
          )}
        </div>
      </FormLayout.Section>
      <FormLayout.Divider withBorder />

      <FormLayout.Section
        heading="Completed Task Notification"
        subTitle="Configure email notifications when this task is completed.  These will include any comments or pictures associated with the task."
      >
        <CompletedActionFormField />
      </FormLayout.Section>

      <FormLayout.Divider withBorder />

      <FormLayout.Section
        heading="Missed Task Notifications"
        subTitle="Configure notifications when this Task is not completed. This is useful for notifying a Position about a Task that was MISSED after the fact."
      >
        <MissedActionFormField actionType="notification" />
      </FormLayout.Section>
    </FormLayout>
  )
}

TaskInfoFormSection.propTypes = {}

export { TaskInfoFormSection }
