import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import {
  TableGroup,
  Tag,
  Constants,
  Label,
  Card,
  Modal,
  Inline,
  Textarea,
  Button,
  DateTime,
} from '@therms/atalaya'
import { TasksLoadTaskCompletionsOkResponseBody } from '@src/services/api-client'
import { FiCheck, FiThumbsDown, FiThumbsUp } from 'react-icons/fi'
import { CgComment, CgPhotoscan } from 'react-icons/cg'
import dayjs from 'dayjs'
import { PositionName } from '@src/modules/Positions/shared/PositionName'
import { TaskGroupName } from '@src/modules/Tasks/shared/TaskGroupName'
import { UserName } from '@src/modules/Users/shared/UserName'
import Tippy from '@tippyjs/react'
import { UserNames } from '@src/modules/Users/shared/UserNames'
import calendar from 'dayjs/plugin/calendar'
import { ButtonWithModal } from '@src/components/ButtonWithModal'
import { MarkTaskCompletedForm } from '@src/modules/Tasks/components/MarkTaskCompleted'
import { Comments } from '@src/modules/Comments/shared/Comments'
import { CommentImageLightbox } from '@src/modules/Comments/shared/CommentImageLightbox'
import { EvaluateTaskCompletion } from '../../../services/api-client'
import { Alerts } from '@src/components/Alerts'
import { humanizeMinutes } from '@therms/web-js'
import maxSize from 'popper-max-size-modifier'

dayjs.extend(calendar)

function TaskActivityTableRow({
  refresh,
  rowData,
  rowKey,
}: {
  refresh: () => void
  rowData: TasksLoadTaskCompletionsOkResponseBody['completions'][number]
  rowKey: string
}) {
  // const { push } = useHistory()
  const FILLCOLOR = '#e5e7eb'
  const [showImages, setShowImages] = useState(false)
  const [upvoted, setUpvoted] = useState(
    rowData.evaluation?.sentiment === 'positive',
  )
  const [downvoted, setDownvoted] = useState(
    rowData.evaluation?.sentiment === 'negative',
  )
  const [evalModal, setEvalModal] = useState(false)
  const [evalNote, setEvalNote] = useState('')
  const [evalPositive, setEvalPositive] = useState(false)
  const votingLocked = !!rowData.evaluation
  const taskCompletionStatus = useMemo(() => {
    if (rowData.isSkipped) {
      return <Tag value="Skipped" variant="caution" />
    }

    if (rowData.isComplete) {
      return <FiCheck color={Constants.Color.Dark.Positive} size={14} />
    }

    if (!rowData.isComplete && rowData.dueDate < new Date().toISOString()) {
      return <Tag value="Missed" variant="critical" />
    }

    return null
  }, [rowData])

  const upvoteOver = () => {
    if (!votingLocked) setUpvoted(true)
  }

  const upvoteOut = () => {
    if (!votingLocked) setUpvoted(false)
  }

  const downvoteOver = () => {
    if (!votingLocked) setDownvoted(true)
  }

  const downvoteOut = () => {
    if (!votingLocked) setDownvoted(false)
  }

  const upvote = () => {
    if (!votingLocked) {
      setEvalPositive(true)
      setEvalModal(true)
      setEvalNote('')
    }
  }

  const downvote = () => {
    if (!votingLocked) {
      setEvalPositive(false)
      setEvalModal(true)
      setEvalNote('')
    }
  }

  const submitEval = useCallback(() => {
    EvaluateTaskCompletion({
      completionId: rowData.id,
      siteId: rowData.siteId,
      sentiment: evalPositive ? 'positive' : 'negative',
      note: evalNote,
    })
      .then(refresh)
      .catch(() => {
        Alerts.error('There was a problem saving the Evaluation')
      })
  }, [evalNote, evalPositive])

  const dateFormatted = useMemo(() => {
    try {
      return dayjs(rowData.dueDate).calendar()
    } catch (e) {
      Sentry.captureException(e)
      return ''
    }
  }, [rowData])

  const inFuture = rowData.dueDate > new Date().toISOString()

  // Popper option for limiting the height of the Tippy popup and enable scrolling
  const popperOptions = {
    modifiers: [
      maxSize,
      {
        name: 'applyMaxSize',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['maxSize'],
        fn({ state }) {
          const { height } = state.modifiersData.maxSize
          state.elements.popper.style.setProperty('--max-height', `${height}px`)
          state.styles.popper.display = 'flex'
        },
      },
    ],
  }

  return (
    <>
      <TableGroup.Row
        className={`hover:bg-surface ${inFuture ? 'bg-surface-strong' : ''}`}
        rowKey={rowKey}
        // onClick={() => {
        //   push(generatePath(TASKS_ROUTES.EDIT_TASK, { taskId: rowData.id }))
        // }}
      >
        <TableGroup.Cell>
          <div className="flex mr-xs">
            {taskCompletionStatus ? (
              <div className="pr-xs">{taskCompletionStatus}</div>
            ) : (
              <div className="mr-xs">
                <ButtonWithModal
                  modalElement={({ closeHandler }) => (
                    <Modal
                      closeHandler={closeHandler}
                      header="Mark Task Complete"
                    >
                      <MarkTaskCompletedForm
                        completion={rowData}
                        onCancel={closeHandler}
                        onComplete={() => {
                          closeHandler()
                          refresh()
                        }}
                      />
                    </Modal>
                  )}
                  size="sm"
                  variant="main"
                >
                  <FiCheck color="#fff" size={14} /> Complete
                </ButtonWithModal>
              </div>
            )}
            {dateFormatted}
          </div>
        </TableGroup.Cell>

        <TableGroup.Cell>
          {rowData?.completedAtTime && (
            <>
              <span className="pr-sm">
                <DateTime mode="time" timestamp={rowData.completedAtTime} />
              </span>

              <span className="text-color-neutral">
                {humanizeMinutes(
                  dayjs(rowData.dueDate).diff(
                    rowData.completedAtTime,
                    'minute',
                  ),
                )}
              </span>
            </>
          )}
        </TableGroup.Cell>

        <TableGroup.Cell>
          <Tippy
            disabled={!rowData.commentIds}
            placement="bottom"
            interactive
            popperOptions={popperOptions}
            content={
              <Card background="surface-strong">
                {rowData.commentIds && (
                  <Comments
                    commentIds={rowData.commentIds}
                    siteId={rowData.siteId}
                    className="space-y-4 divide-y divide-neutral-300"
                  />
                )}
              </Card>
            }
          >
            <div className="flex">
              {rowData.completedByUserId && (
                <UserName id={rowData.completedByUserId} />
              )}

              {rowData.commentIds?.length && (
                <span className="pl-xs">
                  <CgComment
                    onClick={() => {
                      setShowImages(true)
                    }}
                  />
                </span>
              )}
              {!!rowData.verificationFileUrls?.length && (
                <span className="pl-xs">
                  <CgPhotoscan />
                </span>
              )}
            </div>
          </Tippy>

          {!rowData.isComplete && rowData.onSiteAtDueDateUserIds && (
            <Tippy
              disabled={!rowData.onSiteAtDueDateUserIds.length}
              content={
                <Card background="surface-strong">
                  <Label>User's on-site at this time</Label>
                  <UserNames ids={rowData.onSiteAtDueDateUserIds} />
                </Card>
              }
            >
              <div className="text-color-neutral text-xs italic">
                {rowData.onSiteAtDueDateUserIds.length} users on-site
              </div>
            </Tippy>
          )}
        </TableGroup.Cell>

        <TableGroup.Cell>
          {rowData.taskGroupIds?.length ? (
            <Label>
              <TaskGroupName id={rowData.taskGroupIds[0]} />
            </Label>
          ) : null}

          <span className="ml-xs">{rowData.title}</span>

          <div className="text-color-neutral-faded text-sm whitespace-pre">
            {rowData.positionIds.map((positionId, i) => (
              <>
                <PositionName id={positionId} />
                {rowData.positionIds.length - 1 === i ? '' : ', '}
              </>
            ))}
          </div>
        </TableGroup.Cell>
        <TableGroup.Cell>
          {rowData.isComplete && (
            <Inline>
              <FiThumbsUp
                onClick={upvote}
                onMouseOver={upvoteOver}
                onMouseOut={upvoteOut}
                fill={upvoted ? FILLCOLOR : ''}
                size="1.5em"
              />
              <FiThumbsDown
                onClick={downvote}
                onMouseOver={downvoteOver}
                onMouseOut={downvoteOut}
                fill={downvoted ? FILLCOLOR : ''}
                className="ml-3"
                size="1.5em"
              />
            </Inline>
          )}
        </TableGroup.Cell>
      </TableGroup.Row>
      {showImages && (
        <CommentImageLightbox
          onClosePress={() => {
            setShowImages(false)
          }}
          siteId={rowData.siteId}
          commentIds={rowData.commentIds}
        />
      )}
      {evalModal && (
        <Modal
          closeHandler={() => setEvalModal(false)}
          escPressClose
          header={`You are giving this task a ${
            evalPositive ? 'positive' : 'negative'
          } review`}
        >
          <div>
            <Textarea
              label="Reason"
              onChangeValue={(val) => {
                setEvalNote(val)
              }}
            />
            <div className="flex justify-end mt-sm">
              <Button onClick={submitEval} className="mt-3" variant="main">
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

TaskActivityTableRow.propTypes = {
  refresh: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  rowKey: PropTypes.any.isRequired,
}

export { TaskActivityTableRow }
